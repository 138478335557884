import Vue from "vue";
import Router from "vue-router";
import App from "@/pages/App";
import Login from "@/pages/Login";
import Register from "@/pages/Register";
import ApplyToTeam from "@/pages/ApplyToTeam";
import RegisterForTeam from "@/pages/RegisterForTeam";
import RequestInvitation from "@/pages/RequestInvitation";
import Recover from "@/pages/Recover";
import Reset from "@/pages/Reset";
// App pages
import Dashboard from "@/pages/App/Dashboard";
import Deliverables from "@/pages/App/Deliverables";
import Pipelines from "@/pages/App/Pipelines";
import Lessons from "@/pages/App/Lessons";
import Content from "@/pages/App/Content";
import Supplement from "@/pages/App/Supplement";
import Users from "@/pages/App/Users";
import Chat from "@/pages/App/Chat";
import Calendar from "@/pages/App/Calendar";
import FAQs from "@/pages/App/FAQs";
import Reports from "@/pages/App/Reports";
import Attendance from "@/pages/App/Attendance";
import Programs from "@/pages/App/Programs";
import OrganizationSettings from "@/pages/OrganizationSettings";
import UserProfile from "@/pages/UserProfile";
import Billing from "@/pages/Billing";
import Terms from "@/pages/Terms";
import Privacy from "@/pages/Privacy";
import Launch from "@/pages/Launch";
import Onboarding from "@/pages/Onboarding";
import dotenv from "dotenv";
import goTo from "vuetify/es5/services/goto";

dotenv.config();
Vue.use(Router);

/**
 * Helper function for truthines of environment variables
 */
function isEnabled(route) {
	return (route.enabled !== false && route.enabled != "0") || route.enabled == "1" || route.enabled == 1;
}

/**
 * Is the given value true?
 * @param {string|number|boolean} value
 */
function isTrue(value) {
	return (
		value === true ||
		value == "1" ||
		value == 1 ||
		value == "t" ||
		value == "true" ||
		value == "yes" ||
		value == "on"
	);
}

/**
 * Beta features are denoted by environment variables with a value of 2
 * @param {int} value
 */
function isBetaFeature(value) {
	let resolvedValue = value;
	if (typeof value === "string" && value.length > 1) {
		const singularKey = `VUE_APP_FEATURE_${value.toUpperCase()}`;
		const pluralKey = `VUE_APP_FEATURE_${(value + "S").toUpperCase()}`;
		resolvedValue = process.env[singularKey] || process.env[pluralKey];
	}
	const result = resolvedValue == "2" || parseInt(resolvedValue) == 2;
	return result;
}

/**
 * Is an enabled feature
 * @param {int} value
 */
function isEnabledFeature(feature) {
	if (isBetaFeature(feature)) {
		return true;
	}
	if (typeof feature === "string" && feature.length > 1) {
		const index = feature.toUpperCase().startsWith("VUE_APP_FEATURE_")
			? feature.toUpperCase()
			: "VUE_APP_FEATURE_" + feature.toUpperCase();
		if (process.env.hasOwnProperty(index)) {
			return isTrue(process.env[index]);
		}
	}
	return false;
}

const router = new Router({
	mode: "history",
	scrollBehavior: (to, from, savedPosition) => {
		let scrollTo = 0;

		if (to.hash) {
			scrollTo = to.hash;
		} else if (savedPosition) {
			scrollTo = savedPosition.y;
		}

		return goTo(scrollTo);
	},
	routes: [
		{
			alias: "/",
			path: "/login",
			name: "login",
			meta: {
				auth: false,
				title: "WeThrive - Login",
			},
			component: Login,
		},
		{
			path: "/:team/login",
			name: "team-login",
			meta: {
				auth: false,
				title: "WeThrive - Login to a team",
			},
			component: Login,
		},
		{
			path: "/register",
			name: "register",
			component: Register,
			meta: {
				auth: null,
				title: "WeThrive - Register",
			},
			// beforeEnter: function(to, from, next) {
			// 	if (isEnabledFeature("registration")) {
			// 		next();
			// 	} else {
			// 		next({ name: "login" });
			// 	}
			// },
		},
		{
			path: "/:team/register",
			name: "register-for-team",
			component: RegisterForTeam,
			meta: {
				auth: false,
				title: "WeThrive - Register for a team",
			},
			// beforeEnter: function(to, from, next) {
			// 	if (isEnabledFeature("registration")) {
			// 		next();
			// 	} else {
			// 		next({ name: "login" });
			// 	}
			// },
		},
		{
			path: "/:team/apply",
			alias: "/:team/profile",
			name: "apply-to-team",
			component: ApplyToTeam,
			meta: {
				auth: false,
				title: "WeThrive - Apply for a team",
			},
		},
		{
			path: "/:team/join",
			name: "team-request-invite",
			component: RequestInvitation,
			meta: {
				auth: false,
				title: "WeThrive - Request an invitation to a team",
			},
		},
		{
			path: "/:team/launch",
			name: "team-launch",
			component: Launch,
			meta: {
				title: "WeThrive - Launch",
			},
		},
		{
			path: "/recover",
			name: "recover",
			component: Recover,
			meta: {
				auth: false,
				title: "WeThrive - Recover your password",
			},
		},
		{
			path: "/reset/:code?",
			alias: "/password/reset/:code?",
			name: "reset",
			component: Reset,
			meta: {
				auth: false,
				title: "WeThrive - Reset your password",
			},
		},
		{
			path: "/terms",
			alias: "/terms-and-conditions",
			auth: null,
			name: "terms-and-conditions",
			component: Terms,
			meta: {
				title: "WeThrive - Terms and Conditions",
			},
		},
		{
			path: "/privacy",
			alias: "/privacy-policy",
			auth: null,
			name: "privacy-policy",
			component: Privacy,
			meta: {
				title: "WeThrive - Privacy Policy",
			},
		},
		{
			path: "/onboarding",
			name: "onboarding",
			component: Onboarding,
			meta: {
				title: "WeThrive - Onboarding",
			},
			children: [
				{
					path: ":onboarding?",
					name: "onboarding.index",
					meta: {
						title: "WeThrive - Onboarding",
					},
					component: () => import("@/components/Onboarding"),
				},
			]
		},
		{
			path: "/account/:state?",
			name: "account",
			meta: {
				auth: true,
				title: "Manage your account",
			},
			component: UserProfile,
		},
		{
			path: "/billing/:state?",
			name: "billing",
			enabled: process.env.VUE_APP_FEATURE_BILLING,
			meta: {
				auth: true,
				title: "Manage your billing",
			},
			component: Billing,
		},
		{
			path: "/settings/:state?",
			name: "settings",
			meta: {
				auth: {
					roles: ["organization-admin", "owner", "mentor"],
					redirect: {
						name: "account",
					},
				},
				title: "Edit organization settings",
			},
			component: OrganizationSettings,
		},
		{
			path: "/app",
			alias: "/:team/app",
			auth: true,
			redirect: "/app/dashboard",
		},
		{
			path: "/:team/app/dashboard",
			auth: true,
			redirect: "/app/dashboard",
		},
		{
			path: "/:team?/app",
			alias: "/:team?",
			name: "app",
			meta: {
				auth: true,
				title: "WeThrive",
			},
			component: App,
			children: [
				{
					path: "dashboard",
					name: "dashboard",
					meta: {
						auth: true,
						title: "WeThrive",
					},
					component: Dashboard,
				},
				{
					path: "deliverables",
					meta: {
						auth: true,
						title: "WeThrive - Deliverables",
					},
					component: Deliverables,
					children: [
						{
							path: "",
							name: "deliverable.index",
							meta: {
								auth: true,
								title: "WeThrive - Deliverables",
							},
							component: () => import("@/components/Deliverables"),
						},
						{
							path: "create",
							name: "deliverable.create",
							meta: {
								auth: {
									roles: ["organization-admin", "super-admin", "owner", "mentor"],
									redirect: {
										name: "deliverable.index",
									},
								},
								title: "WeThrive - Create Deliverable",
							},
							component: () => import("@/components/Deliverables/Save"),
						},
						{
							path: ":id/update",
							alias: ":id/edit",
							name: "deliverable.update",
							meta: {
								auth: {
									roles: ["organization-admin", "super-admin", "owner", "mentor"],
									redirect: {
										name: "deliverable.index",
									},
								},
								title: "WeThrive - Update Deliverable",
							},
							component: () => import("@/components/Deliverables/Save"),
						},
						{
							path: ":id/view",
							name: "deliverable.view",
							meta: {
								auth: true,
								title: "WeThrive - Deliverables",
							},
							component: () => import("@/components/Deliverables/View"),
						},
						{
							path: ":id/submitted",
							name: "deliverable.submitted",
							meta: {
								auth: true,
								title: "WeThrive - Deliverables",
							},
							component: () => import("@/components/Deliverables/Submitted"),
						},
						{
							path: ":deliverable/submit",
							name: "deliverable.submit",
							meta: {
								auth: true,
								title: "WeThrive - Update Submitted Deliverable",
							},
							component: () => import("@/components/Deliverables/SubmitForm"),
						},
						{
							path: ":deliverable/submitted/:id",
							name: "deliverable-submitted.view",
							meta: {
								auth: true,
								title: "WeThrive - Submitted Deliverable",
							},
							component: () =>
								import("@/components/Deliverables/ViewSubmitted"),
						},
						{
							path: ":deliverable/submitted/:id/update",
							name: "deliverable-submitted.update",
							meta: {
								auth: true,
								title: "WeThrive - Update Submitted Deliverable",
							},
							component: () => import("@/components/Deliverables/SubmitForm"),
						},
					],
				},
				{
					path: "pipelines",
					meta: {
						auth: {
							roles: ["organization-admin", "owner", "mentor"],
							redirect: {
								name: "account",
							},
						},
						title: "WeThrive - Pipelines",
					},
					component: Pipelines,
					children: [
						{
							path: "",
							name: "pipeline.index",
							meta: {
								auth: true,
								title: "WeThrive - Pipelines",
							},
							component: () => import("@/components/Organization/Pipelines"),
						},
						{
							path: "create",
							name: "pipeline.create",
							meta: {
								auth: {
									roles: ["organization-admin", "super-admin", "owner"],
									redirect: {
										name: "pipeline.index",
									},
								},
								title: "WeThrive - Create Pipeline",
							},
							component: () =>
								import("@/components/Organization/Pipelines/Save"),
						},
						{
							path: ":id/update",
							alias: ":id/edit",
							name: "pipeline.update",
							meta: {
								auth: {
									roles: ["organization-admin", "super-admin", "owner"],
									redirect: {
										name: "pipeline.index",
									},
								},
								title: "WeThrive - Update Pipeline",
							},
							component: () =>
								import("@/components/Organization/Pipelines/Save"),
						},
						{
							path: ":id/view",
							name: "pipeline.view",
							meta: {
								auth: true,
								title: "WeThrive - View Pipeline",
							},
							component: () =>
								import("@/components/Organization/Pipelines/View"),
						},
						{
							path: ":pipeline/submitted",
							name: "pipeline.submitted",
							meta: {
								auth: true,
								title: "WeThrive - Submitted Applications",
							},
							component: () =>
								import("@/components/Organization/Pipelines/Submitted"),
						},
						{
							path: ":pipeline/submit",
							name: "pipeline.submit",
							meta: {
								auth: true,
								title: "WeThrive - Update Submitted Application",
							},
							component: () =>
								import("@/components/Organization/Pipelines/SubmitForm"),
						},
						{
							path: ":pipeline/submitted/:id",
							name: "pipeline-submitted.view",
							meta: {
								auth: true,
								title: "WeThrive - View Submitted Application",
							},
							component: () =>
								import("@/components/Organization/Pipelines/ViewSubmitted"),
						},
						{
							path: ":pipeline/submitted/:id/update",
							name: "pipeline-submitted.update",
							meta: {
								auth: true,
								title: "WeThrive - Update Submitted Application",
							},
							component: () =>
								import("@/components/Organization/Pipelines/SubmitForm"),
						},
						{
							path: ":pipeline/evaluated",
							name: "pipeline.evaluated",
							meta: {
								auth: true,
								title: "WeThrive - View Evaluated Applications",
							},
							component: () => import("@/components/Deliverables/Submitted"),
						},
						{
							path: ":pipeline/evaluate",
							name: "pipeline.evaluate",
							meta: {
								auth: true,
								title: "WeThrive - Evaluate Application",
							},
							component: () =>
								import(
									"@/components/Organization/Pipelines/SubmitEvaluationForm"
								),
						},
						{
							path: ":pipeline/evaluated/:id",
							name: "pipeline-evaluated.view",
							meta: {
								auth: true,
								title: "WeThrive - View Application Evaluation",
							},
							component: () =>
								import("@/components/Organization/Pipelines/ViewEvaluated"),
						},
						{
							path: ":pipeline/evaluated/:id/update",
							name: "pipeline-evaluated.update",
							meta: {
								auth: true,
								title: "WeThrive - Update Evaluated Application",
							},
							component: () =>
								import("@/components/Organization/Pipelines/SubmitForm"),
						},
						{
							path: ":pipeline/evaluation-form",
							name: "pipeline.evaluation-form",
							meta: {
								auth: true,
								title: "WeThrive - Update Application Evaluation Form",
							},
							component: () =>
								import(
									"@/components/Organization/Pipelines/SaveEvaluationForm"
								),
						},
						{
							path: ":pipeline/stages",
							name: "pipeline-stage.index",
							meta: {
								auth: true,
								title: "WeThrive - Pipeline Stages",
							},
							component: () =>
								import("@/components/Organization/Pipelines/Stages"),
						},
						{
							path: ":pipeline/stages/create",
							name: "pipeline-stage.create",
							meta: {
								auth: {
									roles: ["organization-admin", "super-admin", "owner"],
									redirect: {
										name: "pipeline-stage.index",
									},
								},
								title: "WeThrive - Create Pipeline Stage",
							},
							component: () =>
								import("@/components/Organization/Pipelines/SaveStage"),
						},
						{
							path: ":pipeline/stages/:id/update",
							alias: ":pipeline/stages/:id/edit",
							name: "pipeline-stage.update",
							meta: {
								auth: {
									roles: ["organization-admin", "super-admin", "owner"],
									redirect: {
										name: "pipeline-stage.index",
									},
								},
								title: "WeThrive - Update Pipeline Stage",
							},
							component: () =>
								import("@/components/Organization/Pipelines/SaveStage"),
						},
						{
							path: ":pipeline/stages/:id/view",
							name: "pipeline-stage.view",
							meta: {
								auth: true,
								title: "WeThrive - View Pipeline Stage",
							},
							component: () =>
								import("@/components/Organization/Pipelines/ViewStage"),
						},
						{
							path: ":pipeline/stages/:stage/submitted",
							name: "pipeline-stage.submitted",
							meta: {
								auth: true,
								title: "WeThrive - Applictions Submitted for Pipeline Stage",
							},
							component: () =>
								import("@/components/Organization/Pipelines/StageSubmitted"),
						},
						{
							path: ":pipeline/stages/:stage/submit",
							name: "pipeline-stage.submit",
							meta: {
								auth: true,
								title: "WeThrive - Submit Stage Application",
							},
							component: () =>
								import("@/components/Organization/Pipelines/SubmitFormStage"),
						},
						{
							path: ":pipeline/stages/:stage/submitted/:id",
							name: "pipeline-stage-submitted.view",
							meta: {
								auth: true,
								title: "WeThrive - Submitted Pipeline",
							},
							component: () =>
								import(
									"@/components/Organization/Pipelines/ViewSubmittedStage"
								),
						},
						{
							path: ":pipeline/stages/:stage/submitted/:id/update",
							name: "pipeline-stage-submitted.update",
							meta: {
								auth: true,
								title: "WeThrive - Update Submitted Pipeline",
							},
							component: () =>
								import("@/components/Organization/Pipelines/SubmitFormStage"),
						},
						{
							path: ":pipeline/stages/:stage/evaluated",
							name: "pipeline-stage.evaluated",
							meta: {
								auth: true,
								title: "WeThrive - Pipelines",
							},
							component: () =>
								import("@/components/Organization/Pipelines/Evaluated"),
						},
						{
							path: ":pipeline/stages/:stage/evaluate",
							name: "pipeline-stage.evaluate",
							meta: {
								auth: true,
								title: "WeThrive - Evaluation Stage Application",
							},
							component: () =>
								import(
									"@/components/Organization/Pipelines/SubmitEvaluationForm"
								),
						},
						{
							path: ":pipeline/stages/:stage/evaluated/:id",
							name: "pipeline-stage-evaluated.view",
							meta: {
								auth: true,
								title: "WeThrive - View Application Evaluation",
							},
							component: () =>
								import("@/components/Organization/Pipelines/ViewEvaluated"),
						},
						{
							path: ":pipeline/stages/:stage/evaluated/:id/update",
							name: "pipeline-stage-evaluated.update",
							meta: {
								auth: true,
								title: "WeThrive - Update Stage Application Evaluation",
							},
							component: () =>
								import(
									"@/components/Organization/Pipelines/SubmitEvaluationForm"
								),
						},
						{
							path: ":pipeline/stages/:stage/evaluation-form",
							name: "pipeline-stage.evaluation-form",
							meta: {
								auth: true,
								title: "WeThrive - Update Application Stage Evaluation Form",
							},
							component: () =>
								import(
									"@/components/Organization/Pipelines/SaveEvaluationForm"
								),
						},
					],
				},
				{
					path: "lessons",
					enabled: process.env.VUE_APP_FEATURE_LESSONS,
					meta: {
						auth: true,
						title: "WeThrive - Lessons",
					},
					component: Lessons,
					children: [
						{
							path: "",
							name: "lesson.index",
							meta: {
								auth: true,
								title: "WeThrive - Lessons",
							},
							component: () => import("@/components/Lessons"),
						},
						{
							path: "create",
							name: "lesson.create",
							meta: {
								auth: {
									roles: ["organization-admin", "super-admin", "owner", "mentor"],
									redirect: {
										name: "lesson.index",
									},
								},
								title: "WeThrive - Create Lesson",
							},
							component: () => import("@/components/Lessons/Save"),
						},
						{
							path: ":id/update",
							alias: ":id/edit",
							name: "lesson.update",
							meta: {
								auth: {
									roles: ["organization-admin", "super-admin", "owner", "mentor"],
									redirect: {
										name: "lesson.index",
									},
								},
								title: "WeThrive - Update Lesson",
							},
							component: () => import("@/components/Lessons/Save"),
						},
						{
							path: ":id",
							name: "lesson.view",
							meta: {
								auth: true,
								title: "WeThrive - Lessons",
							},
							component: () => import("@/components/Lessons/View"),
						},
						{
							path: ":id/submitted",
							name: "lesson.submitted",
							meta: {
								auth: true,
								title: "WeThrive - Lessons",
							},
							component: () => import("@/components/Lessons/Submitted"),
						},
						{
							path: ":lesson/submit",
							name: "lesson.submit",
							meta: {
								auth: true,
								title: "WeThrive - Update Submitted Lesson",
							},
							component: () =>
								import("@/components/Lessons/Submitted/SubmitForm"),
						},
						{
							path: ":lesson/submitted/:id",
							name: "lesson-submitted.view",
							meta: {
								auth: true,
								title: "WeThrive - Submitted Lesson",
							},
							component: () =>
								import("@/components/Lessons/Submitted/ViewSubmitted"),
						},
						{
							path: ":lesson/submitted/:id/update",
							name: "lesson-submitted.update",
							meta: {
								auth: true,
								title: "WeThrive - Update Submitted Lesson",
							},
							component: () =>
								import("@/components/Lessons/Submitted/SubmitForm"),
						},
					],
				},
				{
					path: "worksheets",
					enabled: process.env.VUE_APP_FEATURE_WORKSHEETS,
					meta: {
						auth: true,
						title: "WeThrive - Worksheets",
					},
					component: Lessons,
					children: [
						{
							path: "",
							name: "worksheet.index",
							meta: {
								auth: true,
								title: "WeThrive - Worksheets",
							},
							component: () => import("@/components/Lessons/Worksheets"),
						},
						{
							path: "create",
							name: "worksheet.create",
							meta: {
								auth: {
									roles: ["organization-admin", "super-admin", "owner"],
									redirect: {
										name: "worksheet.index",
									},
								},
								title: "WeThrive - Create Worksheets",
							},
							component: () => import("@/components/Lessons/Save"),
						},
						{
							path: ":id/update",
							alias: ":id/edit",
							name: "worksheet.update",
							meta: {
								auth: {
									roles: ["organization-admin", "super-admin", "owner"],
									redirect: {
										name: "worksheet.index",
									},
								},
								title: "WeThrive - Update Worksheets",
							},
							component: () => import("@/components/Lessons/Save"),
						},
						{
							path: ":id",
							name: "worksheet.view",
							meta: {
								auth: true,
								title: "WeThrive - Worksheets",
							},
							component: () => import("@/components/Lessons/View"),
						},
					],
				},
				{
					path: "present",
					alias: "p",
					meta: {
						auth: true,
						title: "WeThrive - Presentation",
					},
					component: Lessons,
					children: [
						{
							path: ":type/:id/:cardId?",
							name: "present.view",
							meta: {
								auth: true,
								title: "WeThrive - View Presentation",
							},
							component: () => import("@/components/Lessons/Present"),
						},
					],
				},
				{
					path: "chat",
					meta: {
						auth: true,
						title: "WeThrive - Chat",
					},
					component: Chat,
					children: [
						{
							path: ":id?",
							name: "chat.index",
							meta: {
								auth: true,
								title: "WeThrive - Chat",
							},
							component: () => import("@/components/Chat"),
						},
					],
				},
				{
					enabled: isEnabledFeature("CALENDAR"),
					path: "calendar",
					meta: {
						auth: true,
						title: "WeThrive - Calendar",
					},
					component: Calendar,
					children: [
						{
							path: ":id?",
							name: "calendar.index",
							meta: {
								auth: true,
								title: "WeThrive - Calendar",
							},
							component: () => import("@/components/Calendar"),
						},
						{
							path: ":calendarId/:calendarEntryId",
							name: "calendar.event",
							meta: {
								auth: true,
								title: "WeThrive - Calendar",
							},
							component: () => import("@/components/Calendar"),
						},
						{
							path: ":calendarId/:calendarEntryId/status/:status",
							name: "calendar.rsvp-status",
							meta: {
								auth: true,
								title: "WeThrive - Calendar",
							},
							component: () => import("@/components/Calendar"),
						},
						{
							path: "rsvp/:calendarId/:calendarEntryId",
							name: "calendar.rsvp",
							meta: {
								auth: true,
								title: "WeThrive - Calendar",
							},
							component: () => import("@/components/Calendar"),
						},
					],
				},
				{
					path: "faq",
					meta: {
						auth: true,
						title: "WeThrive - FAQs",
					},
					component: FAQs,
					children: [
						{
							path: "",
							name: "faq.index",
							meta: {
								auth: true,
								title: "WeThrive - FAQs",
							},
							component: () => import("@/components/FAQ"),
						},
						{
							path: "list",
							name: "faq.list",
							meta: {
								auth: true,
								title: "WeThrive - FAQs",
							},
							component: () => import("@/components/FAQ/Save"),
						},
						{
							path: "create",
							name: "faq.create",
							meta: {
								auth: {
									roles: ["organization-admin", "super-admin", "owner", "mentor"],
									redirect: {
										name: "faq.index",
									},
								},
								title: "WeThrive - Create FAQ",
							},
							component: () => import("@/components/FAQ/Save"),
						},
						{
							path: ":id/update",
							alias: ":id/edit",
							name: "faq.update",
							meta: {
								auth: {
									roles: ["organization-admin", "super-admin", "owner", "mentor"],
									redirect: {
										name: "faq.index",
									},
								},
								title: "WeThrive - Update FAQ",
							},
							component: () => import("@/components/FAQ/Save"),
						},
						{
							path: ":id",
							name: "faq.view",
							meta: {
								auth: true,
								title: "WeThrive - FAQs",
							},
							component: () => import("@/components/FAQ/View"),
						},
					],
				},
				{
					enabled: process.env.VUE_APP_FEATURE_REPORTS,
					path: "reports",
					meta: {
						auth: {
							roles: ["organization-admin", "owner"],
							redirect: {
								name: "account",
							},
						},
						title: "WeThrive - Reports",
					},
					component: Reports,
					children: [
						{
							path: "",
							name: "report.index",
							meta: {
								auth: true,
								title: "WeThrive - Reports",
							},
							component: () => import("@/components/Reports"),
						},
						{
							path: "dashboard/:id?",
							name: "report.dashboard",
							meta: {
								auth: true,
								title: "WeThrive - Reports",
							},
							component: () => import("@/components/Reports/Dashboard"),
						},
					],
				},
				{
					path: "attendance",
					meta: {
						auth: true,
						title: "WeThrive - Attendance",
					},
					component: Attendance,
					children: [
						{
							path: "",
							name: "attendance.index",
							meta: {
								auth: true,
								title: "WeThrive - Attendance",
							},
							component: () => import("@/components/Attendance"),
						},
						{
							path: "create",
							name: "attendance.create",
							meta: {
								auth: true,
								title: "WeThrive - Add Attendance",
							},
							component: () => import("@/components/Attendance/Form"),
						},
						{
							path: ":id/update",
							alias: ":id/edit",
							name: "attendance.update",
							meta: {
								auth: true,
								title: "WeThrive - Update Attendance",
							},
							component: () => import("@/components/Attendance/Save"),
						},
						{
							path: ":id",
							name: "attendance.view",
							meta: {
								auth: true,
								title: "WeThrive - Attendance",
							},
							component: () => import("@/components/Attendance/View"),
						},
					],
				},
				{
					path: "supplement",
					enabled: process.env.VUE_APP_FEATURE_SUPPLEMENTS,
					meta: {
						auth: true,
						title: "WeThrive - Supplements",
					},
					component: Supplement,
					children: [
						{
							path: "",
							name: "supplement.index",
							meta: {
								auth: true,
								title: "WeThrive - Supplements",
							},
							component: () => import("@/components/Supplements"),
						},
						{
							path: "create",
							name: "supplement.create",
							meta: {
								auth: {
									roles: [
										"organization-admin",
										"super-admin",
										"owner",
										"mentor",
									],
									redirect: {
										name: "supplement.index",
									},
								},
								title: "WeThrive - Create Supplement",
							},
							component: () => import("@/components/Supplements/Save"),
						},
						{
							path: ":id/update",
							alias: ":id/edit",
							name: "supplement.update",
							meta: {
								auth: {
									roles: [
										"organization-admin",
										"super-admin",
										"owner",
										"mentor",
									],
									redirect: {
										name: "supplement.index",
									},
								},
								title: "WeThrive - Update Supplement",
							},
							component: () => import("@/components/Supplements/Save"),
						},
						{
							path: ":id",
							name: "supplement.view",
							meta: {
								auth: {
									roles: [
										"organization-admin",
										"super-admin",
										"owner",
										"mentor",
										"student",
									],
									redirect: {
										name: "account",
									},
								},
								title: "WeThrive - Supplements",
							},
							component: () => import("@/components/Supplements/View"),
						},
					],
				},
				{
					path: "content",
					meta: {
						auth: true,
						title: "WeThrive - Content",
					},
					component: Content,
					children: [
						{
							path: "",
							name: "content.index",
							meta: {
								auth: true,
								title: "WeThrive - Content",
							},
							component: () => import("@/components/Content"),
						},
						{
							path: "create",
							name: "content.create",
							meta: {
								auth: {
									roles: [
										"organization-admin",
										"super-admin",
										"owner",
										"mentor",
									],
									redirect: {
										name: "content.index",
									},
								},
								title: "WeThrive - Create Content",
							},
							component: () => import("@/components/Content/Save"),
						},
						{
							path: ":id/update",
							alias: ":id/edit",
							name: "content.update",
							meta: {
								auth: {
									roles: [
										"organization-admin",
										"super-admin",
										"owner",
										"mentor",
									],
									redirect: {
										name: "content.index",
									},
								},
								title: "WeThrive - Update Content",
							},
							component: () => import("@/components/Content/Save"),
						},
						{
							path: ":id",
							name: "content.view",
							meta: {
								auth: true,
								title: "WeThrive - Content",
							},
							component: () => import("@/components/Content/View"),
						},
						{
							path: ":id/submitted",
							name: "content.submitted",
							meta: {
								auth: true,
								title: "WeThrive - Completed Content",
							},
							component: () => import("@/components/Content/Submitted"),
						},
					],
				},
				{
					path: "users",
					meta: {
						auth: {
							roles: ["organization-admin", "super-admin", "owner"],
							redirect: {
								name: "account",
							},
						},
						title: "WeThrive - Users",
					},
					component: Users,
					children: [
						{
							path: "",
							name: "user.index",
							alias: "user.index",
							meta: {
								auth: {
									roles: ["organization-admin", "super-admin", "owner"],
									redirect: {
										name: "account",
									},
								},
								title: "WeThrive - Users",
							},
							component: () => import("@/components/Users"),
						},
						{
							path: "create",
							name: "user.create",
							meta: {
								auth: {
									roles: ["organization-admin", "super-admin", "owner"],
									redirect: {
										name: "account",
									},
								},
								title: "WeThrive - Create User",
							},
							component: () => import("@/components/Users/Save"),
						},
						{
							path: ":id/update",
							alias: ":id/edit",
							name: "user.update",
							meta: {
								auth: {
									roles: ["organization-admin", "super-admin", "owner"],
									redirect: {
										name: "account",
									},
								},
								title: "WeThrive - Update User",
							},
							component: () => import("@/components/Users/Save"),
						},
						{
							path: ":id",
							name: "user.view",
							meta: {
								auth: true,
								title: "WeThrive - View User",
							},
							component: () => import("@/components/Users/View"),
						},
					],
				},
				{
					path: "programs",
					meta: {
						auth: true,
						title: "WeThrive - Programs",
					},
					component: Programs,
					children: [
						{
							path: "",
							name: "program.index",
							meta: {
								auth: true,
								title: "WeThrive - Programs",
							},
							component: () => import("@/components/Programs/"),
						},
						{
							path: "create",
							name: "program.create",
							meta: {
								auth: {
									roles: ["organization-admin", "super-admin", "owner"],
									redirect: {
										name: "program.index",
									},
								},
								title: "WeThrive - Create Program",
							},
							component: () => import("@/components/Programs/Save"),
						},
						{
							path: "udpate/:id",
							alias: ":id/edit",
							name: "program.update",
							meta: {
								auth: {
									roles: ["organization-admin", "super-admin", "owner"],
									redirect: {
										name: "program.index",
									},
								},
								title: "WeThrive - Update Program",
							},
							component: () => import("@/components/Programs/Save"),
						},
						{
							path: ":id",
							name: "program.view",
							meta: {
								auth: true,
								title: "WeThrive - Programs",
							},
							component: () => import("@/components/Programs/View"),
						},
					],
				},
			].filter((child) => isEnabled(child)),
		},
	].filter((route) => isEnabled(route)),
});

router.beforeEach((to, from, next) => {
	Vue.nextTick(() => {
		document.title =
			to.meta.title instanceof Function
				? to.meta.title(to)
				: to.meta && to.meta.title
				? to.meta.title
				: "WeThrive";
	});
	router.app.redirectUsersToLaunch(to, from, next);
	router.app.redirectUsersToPrograms(to, from, next);
	router.app.switchToTeamFromRouteIfNecessary(to, from, next);
	localStorage.setItem("previous-url", to.fullPath);
});

Vue.router = router;
export default router;
